import React from 'react'
import  './w3.css'

export default function AboutMe() {
    return (
        <div>
        <div id="my_intro" className="w3-card w3-margin w3-margin-top">
          <img src="https://storage.googleapis.com/vebb-d67c1.appspot.com/cricblog_images/my_pic.jpg" alt="" style={{width:"100%"}}></img>
          <div className="w3-container w3-white">
            <h4><b>Prateek Sharma</b></h4>
            <p>Just me, myself and I, exploring the universe of uknownment. I have a heart of love and a interest of
              lorem ipsum and mauris neque quam blog. I want to share my world with you.</p>
          </div>
        </div>
        <hr></hr>
        </div>
    )
}
