import React from 'react'

export default function Tags() {
    return (
        <div>
            <div className="w3-card w3-margin">
          <div className="w3-container w3-padding">
            <h4>Tags</h4>
          </div>
          <div className="w3-container w3-white">
            <p><span className="w3-tag w3-black w3-margin-bottom">Travel</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">New York</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">London</span>
              <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">IKEA</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">NORWAY</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">DIY</span>
              <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Ideas</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">Baby</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">Family</span>
              <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">News</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">Clothing</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">Shopping</span>
              <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Sports</span> <span
                className="w3-tag w3-light-grey w3-small w3-margin-bottom">Games</span>
            </p>
          </div>
        </div>
        </div>
    )
}
