import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Spinner } from 'loading-animations-react';
var dateFormat = require("dateformat");

export default function CompletePost() {

  const [post_obj, setpost_obj] = useState('');
  const [PostErr, setPostErr] = useState('');
  const location = useLocation();
  var id = location.search;
  id = id.substring(3);
  const url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getPost(id);

  },[]);

  const getPost = (id) => {

    var payload = {
      id: id
    }

    axios.post(`${url}posts/getposts`, payload)
      .then((response) => {
        setpost_obj(response.data.post)
        setPostErr(response.data)
      })
      .catch(error => console.error(`Error: ${error}`));
  }

  const showPost = () => {
    console.log(PostErr.status);
    if (PostErr.code === 200) {
      return (
        <div>
        <div className="w3-card-4 w3-margin w3-white">
        <div className="w3-row">

        <div className="w3-col l12 s12">
        <img src={post_obj.photo_url} alt="Nature" style={{width:"100%"}}/>
        <div className="w3-container-complete-post">
        
          <h1>{post_obj.title}</h1>
          <h5>{post_obj.desc}<br></br> <span className="w3-opacity">{
                dateFormat(post_obj.created,'mmmm dd, yyyy')
              }</span></h5>
          
          <p className="displayPost">{post_obj.post_text.replaceAll('\\n', '\n')}</p>
          </div>
          </div>
          </div>
          </div>
        </div>
      )
    }

    else {
      return (
        <div>
          <h1>Error Showing posts</h1>
        </div>
      )
    }

  }

  //console.log(location.pathname);  // result: '/secondpage'
  //console.log(location.search);    // result: '?query=abc'
  // console.log(location.state.id); // result: 'some_value'


  if(post_obj){
    return <div>
    {showPost()}
  </div>
   }
   else{
    return<div style={{"height" : "20%", "width" : "20%","display": "block","margin-top":"170px","margin-left": "auto",
    "margin-right": "auto"}}>
    <Spinner color1="blue" color2="#fff" textColor="rgba(255,255,255, 1)" text="Loading..." style={{"padding-left":"2000px"}} />
    </div>;
   }

}
