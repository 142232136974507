import React from 'react'
import './w3.css'
import SinglePost from './SinglePost'
import AboutMe from './AboutMe'
import PopularPosts from './PopularPosts'
import Tags from './Tags'
import Footer from './Footer'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Spinner } from 'loading-animations-react';
export default function HomePage() {

  var [posts, setposts] = useState('');

  const url = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    getAllPosts();
    console.log("use effect ran");
  },[]);
 
  const getAllPosts = () => {
    console.log("http call here");
    axios.get(`${url}posts`, {
      headers: {
        'Content-Type': null
      }
    })
      .then((response) => {
        //console.log(response);
        const allposts = response.data.posts_array;
        setposts(allposts);
       
      })
      .catch(error => console.error(`Error: ${error}`));
  }



 if(posts){
  return f(posts)
 }
 else{
  return<div style={{"height" : "20%", "width" : "20%","display": "block","margin-top":"170px","margin-left": "auto",
  "margin-right": "auto"}}>
  <Spinner color1="blue" color2="#fff" textColor="rgba(255,255,255, 1)" text="Loading..." style={{"padding-left":"2000px"}} />
  </div>;
 }
}

function f(posts){
return <div>

<div className="w3-row">

  <div className="w3-col l8 s12">
  
  {console.log("Single post html ran")}
    <SinglePost posts={posts} />

  </div>

  <div className="w3-col l4">

    <AboutMe />

    <PopularPosts />
    <hr></hr>

    <Tags />

  </div>

</div>
<Footer />
</div>
}
