import React from 'react'

export default function Error() {
    return (
        <div>
            <h1 style={{color:"white"}}>Error 404</h1>
            <p style={{color:"white"}}>The url you requested is not available.</p>
        </div>
    )
}
